import React, { useState } from "react";
import Button from "../../buttons/button";
import Container from "../../containers/container";
import { useV1ServiceV1CardJamCardSetPost } from "common/api/queries";
import { EmptyState } from "../../attributes/EmptyState";
import { PreviewFlippableCard } from "@/components/card/PreviewFlippableCard";
import { useOrderFormStore } from "store/order-form/order-form";
import ButtonFooter from "../button-footer";
import StepHeader from "../step-header";
import { SetContainer } from "../set-container";
import { sendGTMEvent } from "@next/third-parties/google";
import { CardSetValidateBuild, WorkspaceCardSetResponse } from "common/api/requests";
import isApiError from "@/utils/api/is-api-error";
import { ConfirmModal, showToast } from "legend-ui";
import { isDefaultDesignForSet } from "@/utils/reward";
import useResetCard from "hooks/card/use-reset-card";
import { AnimeOptions, AnimeOptionsModal } from "./anime-options-modal";
import { sendMetaEvent } from "@/utils/send-meta-event";

const betaAttributes = ["anime"];

type Props = {
  onNext: () => void;
  onBack: () => void;
  sets: WorkspaceCardSetResponse[];
  refetchSets: () => void;
};

const AddAttributes = ({ onBack, onNext, sets, refetchSets }: Props) => {
  const {
    cardStepData: { attributesStep, pickASetStep, photoStep },
    setCardStep,
    setAttributesStep
  } = useOrderFormStore();
  const [selectedAttributeNames, setSelectedAttributeNames] = useState(
    attributesStep?.selectedAttributes ?? []
  );
  const { reset } = useResetCard(sets);
  const [confirmResetAttribute, setConfirmResetAttribute] = useState<string>();
  const [animeOptionsOpen, setAnimeOptionsOpen] = useState(false);
  const { mutateAsync: validateSet } = useV1ServiceV1CardJamCardSetPost();
  const [validatingAttributes, setValidatingAttributes] = useState(false);
  const { cardSetId, cardVariantBase, cardSetTemplate, cardSetVariantName } = pickASetStep ?? {};

  const selectedSet = sets?.find((result) => result.id === cardSetId);
  const selectedVariant = selectedSet?.card_set_template_variant_bases?.find(
    ({ name }) => name === cardSetVariantName
  );
  const selectedAttributes = selectedSet?.card_variant_attributes?.filter((variant) =>
    selectedAttributeNames.includes(variant.card_variant_attribute)
  );
  const setToPreview = selectedAttributes[0] ?? selectedVariant;
  const isAttributeApplied = (name: string) =>
    photoStep?.attributes && photoStep.attributes.includes(name);
  const allAttributes =
    sets
      ?.find((result) => result.id === cardSetId)
      ?.card_variant_attributes?.filter((variant) => variant.active) ?? [];
  const unlockedAttributesCount = allAttributes.filter(
    (variant) => !variant.locked || isAttributeApplied(variant.card_variant_attribute)
  ).length;

  const onAttributeSelect = (attribute: string) => {
    setSelectedAttributeNames((currentAttributes) => {
      const alreadySelected = currentAttributes.includes(attribute);
      const selectedAttributeLocked = allAttributes.find(
        (a) => a.card_variant_attribute === attribute
      )?.locked;
      const attributes = alreadySelected
        ? currentAttributes.filter((selected) => selected !== attribute)
        : [...currentAttributes, attribute];
      const fullAttributes = attributes.map((name) =>
        allAttributes.find(({ card_variant_attribute }) => card_variant_attribute === name)
      );
      const newAttributes = fullAttributes.filter(
        ({ locked }) => locked === selectedAttributeLocked
      );

      if (attribute === "anime" && !alreadySelected && !selectedAttributeLocked) {
        setAnimeOptionsOpen(true);
      }

      return newAttributes.map((a) => a.card_variant_attribute);
    });
  };

  const onAttributeSelectWithConfirm = (attribute: string) => {
    const hasGeneratedCard = photoStep?.cardId;
    const generatedWithAttributes = photoStep?.attributes ?? [];
    if (
      hasGeneratedCard &&
      (generatedWithAttributes.length > 0 ||
        !isDefaultDesignForSet(sets, photoStep?.setId, photoStep?.template, photoStep?.variant))
    ) {
      setConfirmResetAttribute(attribute);
      return;
    }
    onAttributeSelect(attribute);
  };

  const onAttributeSelectWithReset = () => {
    reset();
    refetchSets();
    onAttributeSelect(confirmResetAttribute);
    setConfirmResetAttribute(undefined);
  };

  const validateAttributes = async (attributes: string[]): Promise<boolean> => {
    try {
      await validateSet({
        cardSetId: cardSetId,
        data: {
          card_set_template: cardSetTemplate as CardSetValidateBuild["card_set_template"],
          card_variant_base: cardVariantBase as CardSetValidateBuild["card_variant_base"],
          card_variant_attributes: attributes as CardSetValidateBuild["card_variant_attributes"]
        }
      });
      return true;
    } catch (e) {
      const message = isApiError(e) ? e.body?.["detail"] : undefined;
      showToast(message ?? "We ran into an unexpected issue. Please try again.");
      return false;
    }
  };

  const updateAnimeOptions = (values: AnimeOptions) => {
    setAttributesStep({ gender: values.gender, skinTone: values.skinTone });
    setAnimeOptionsOpen(false);
  };

  const handleNext = async () => {
    const attributes = selectedAttributes
      .filter(
        (attribute) =>
          attribute.active &&
          (!attribute.locked || isAttributeApplied(attribute.card_variant_attribute))
      )
      .map((attribute) => attribute.card_variant_attribute);

    if (attributes.length > 0) {
      setValidatingAttributes(true);
      const isValid = await validateAttributes(attributes);
      setValidatingAttributes(false);
      if (!isValid) {
        return;
      }
    }

    for (const attribute of attributes) {
      sendGTMEvent({
        event: "attribute_selected", 
        value: {
          attribute: attribute
        }
      });
      sendMetaEvent("track", "CustomizeProduct");
    }

    setAttributesStep({
      selectedAttributes: attributes,
      cardSetId: cardSetId
    });
    onNext();
  };

  return (
    <Container innerClassName="pt-0 px-0">
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">
        <div className="flex flex-col md:flex-grow">
          <StepHeader
            title={"Add Attributes"}
            subtitle={unlockedAttributesCount > 0 ? "Add attributes to your design" : undefined}
            frontUrl={setToPreview?.card_sample_front_url}
            backUrl={setToPreview?.card_sample_back_url}
          />
          <div className="flex flex-col justify-center px-4 md:px-0">
            <div className="flex flex-col gap-y-1 md:mt-3">
              {unlockedAttributesCount === 0 && <EmptyState />}
              {allAttributes.length > 0 && (
                <SetContainer
                  setTitle={""}
                  previews={
                    allAttributes.map((variant) => ({
                      alt: variant.card_variant_attribute,
                      src: variant.card_icon_url,
                      name: variant.card_variant_attribute,
                      locked: variant.locked && !isAttributeApplied(variant.card_variant_attribute),
                      count: !variant.default && variant.count > 0 ? variant.count : undefined,
                      beta: betaAttributes.includes(variant.card_variant_attribute)
                    })) ?? []
                  }
                  onSetClick={({ name }) => onAttributeSelectWithConfirm(name)}
                  selectedVariants={selectedAttributeNames}
                />
              )}
              <div className="mb-6" />
            </div>
            <ButtonFooter>
              <Button className="flex-grow md:grow-0" variant="outline" onClick={onBack}>
                Back
              </Button>
              <Button
                className="flex-grow md:grow-0"
                onClick={handleNext}
                disabled={validatingAttributes}
                loading={validatingAttributes}
              >
                Next
              </Button>
            </ButtonFooter>
          </div>
        </div>

        <div className="hidden items-start md:flex md:flex-col">
          <PreviewFlippableCard
            frontUrl={setToPreview?.card_sample_front_url}
            backUrl={setToPreview?.card_sample_back_url}
          />
        </div>
        <ConfirmModal
          title="Change attribute?"
          description="The special design and attributes applied to your current card will be lost."
          onConfirm={onAttributeSelectWithReset}
          onCancel={() => setConfirmResetAttribute(undefined)}
          open={!!confirmResetAttribute}
        />
        <AnimeOptionsModal
          onSubmit={updateAnimeOptions}
          onSkip={() => setAnimeOptionsOpen(false)}
          defaultOptions={
            attributesStep?.gender || attributesStep?.skinTone
              ? {
                  gender: attributesStep?.gender,
                  skinTone: attributesStep?.skinTone
                }
              : undefined
          }
          open={animeOptionsOpen}
        />
      </div>
    </Container>
  );
};

export default AddAttributes;
