import { useEffect, useState } from "react";
import Container from "../../containers/container";
import { PlayerFlippableCard } from "../../card/PlayerFlippableCard";
import { useFetchCardProductPricing } from "hooks/queries/useFetchCardProductPricing";
import { useUpdateCard } from "./use-update-card";
import AboutAthleteBackForm from "./about-athlete-back-form";
import { useOrderFormStore } from "store/order-form/order-form";
import { AboutAthleteBackStep } from "store/order-form/card";
import ConfirmCard from "./confirm-card";
import useScrollToTop from "hooks/use-scroll-to-top";
import {
  useV1ServiceV1OrderManagementWorkspaceCartCartItemPost,
  useV1ServiceV1TeamManagementTeamTeamCardCreate
} from "common/api/queries";
import PlayerStepHeader from "../player-step-header";
import useAuth from "hooks/useAuth";
import useProgress from "../upload/use-progress";
import { useGetPlayerCard } from "hooks/card/useGetPlayerCard";
import { sendGTMEvent } from "@next/third-parties/google";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { showToast } from "legend-ui";
import isApiError from "@/utils/api/is-api-error";
import { sendMetaEvent } from "@/utils/send-meta-event";

type Props = {
  onNext: () => void;
  onBack: () => void;
};

export default function AboutAthleteBack({ onBack, onNext }: Props) {
  useScrollToTop();
  const params = useSearchParams();
  const flow = params.get("flow");
  const router = useRouter();
  const { session } = useAuth();
  const { cardStepData, setCardStep, clear: clearOrderFormStore } = useOrderFormStore();
  const [confirmOpen, setConfirmOpen] = useState({ open: false, backChanged: false });
  const onAddToCartSuccess = () => {
    setCardStep({
      confirmStep: {
        confirmedCardId: cardStepData.photoStep?.cardId
      }
    });
    if (flow === "download") {
      router.push("/download");
    } else {
      showToast("This card has been added to your cart.");
      onNext();
    }
  };
  const { mutateAsync: addCardToTeam, isPending: isAddingToTeam } =
    useV1ServiceV1TeamManagementTeamTeamCardCreate();
  const { mutate: addItemToCart, isPending: isAddingToCart } =
    useV1ServiceV1OrderManagementWorkspaceCartCartItemPost({
      onSuccess: onAddToCartSuccess,
      onError: (error) => {
        if (isApiError(error) && error.status === 400) {
          const message = error.body?.["detail"] ?? "";
          if (message.includes("exists")) {
            onAddToCartSuccess();
            return;
          }
        }
        showToast(
          "We ran into an unexpected issue adding this item to your cart. Please try again."
        );
      }
    });
  const { refetch: prefetchPricing } = useFetchCardProductPricing();
  const { cardResult, isLoadingCard, isGeneratingCard } = useGetPlayerCard();
  const { progress, pollingForProgress, startPolling, stopPolling } = useProgress({
    cardResult
  });
  const cardLoading = pollingForProgress || isLoadingCard || isGeneratingCard;
  const { updateCard } = useUpdateCard({ onUpdateError: () => stopPolling() });

  useEffect(() => {
    prefetchPricing();
  }, []);

  const handleNext = (values: AboutAthleteBackStep, backInfoChanged: boolean) => {
    if (backInfoChanged) {
      startPolling();
    }
    setConfirmOpen({
      open: true,
      backChanged: backInfoChanged
    });
    setCardStep({
      aboutAthleteBackStep: values
    });
  };

  const handleBlurbChange = async (blurb: string) => {
    startPolling();
    const success = await updateCard(cardStepData, { blurb });
    if (success) {
      setCardStep({
        blurbStep: {
          blurb,
          overriden: true
        }
      });
    } else {
      stopPolling();
    }
  };

  const confirmAndAddToCart = () => {
    sendGTMEvent({
      event: "add_to_cart", 
      value: {
        items: [
          {
            item_id: cardStepData.photoStep.cardId
          }
        ]
      },
    });
    sendMetaEvent("track", "AddToCart");
    addItemToCart({
      data: {
        quantity: 1,
        card_id: cardStepData.photoStep.cardId
      },
      workspaceId: session?.workspaceId
    });
  };

  const handleAddCardToTeam = async () => {
    try {
      const teamId = params.get("teamId");
      if (!teamId) {
        showToast("Team ID was not valid, please try again.");
        return;
      }
      await addCardToTeam({
        data: {
          card_id: cardResult?.id,
          workspace_id: cardResult?.workspace_id,
          workspace_type: cardResult?.workspace_type,
          athlete_name: cardResult.athlete_info?.full_name ?? ""
        },
        teamId
      });
      await router.push(`/team/${teamId}`);
      clearOrderFormStore();
    } catch (e) {
      showToast("We ran into an issue. Please try again.");
    }
  };

  const handleConfirm = () => {
    if (flow === "team") {
      handleAddCardToTeam();
      return;
    }
    confirmAndAddToCart();
  };

  return (
    <Container innerClassName="pt-0 px-0">
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">
        <div className="flex flex-grow flex-col">
          <PlayerStepHeader
            title={"Back: Athlete's info"}
            subtitle={
              "Add additional info about the athlete to generate the back side of the card."
            }
            tooltipOnLoad="Card updated"
          />
          <AboutAthleteBackForm onBack={onBack} onSubmit={handleNext} />
        </div>

        <div className="hidden justify-center md:flex md:justify-end">
          <PlayerFlippableCard />
        </div>
        <ConfirmCard
          confirmLoading={isAddingToCart || isAddingToTeam}
          open={confirmOpen.open}
          backChanged={confirmOpen.backChanged}
          onBack={() => setConfirmOpen({ open: false, backChanged: false })}
          onConfirm={handleConfirm}
          onBlurbChange={handleBlurbChange}
          progress={progress?.value ?? 0}
          cardLoading={cardLoading}
          confirmDisabled={isAddingToCart || isAddingToTeam || cardLoading}
          confirmLabel={flow === "team" ? "Add to team" : "Confirm"}
        />
      </div>
    </Container>
  );
}
