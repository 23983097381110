import Button from "@/components/buttons/button";
import { PlayerFlippableCard } from "@/components/card/PlayerFlippableCard";
import {
  DialogHeader,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle
} from "@/components/ui/dialog";
import { useGetPlayerCard } from "hooks/card/useGetPlayerCard";
import { useState } from "react";
import EditBlurbModal from "./edit-blurb-modal";
import { LoadingCard } from "@/components/card/LoadingCard";
import { PencilIcon, Share2Icon } from "lucide-react";
import { IconButton, showToast } from "legend-ui";
import { sendGTMEvent } from "@next/third-parties/google";

type Props = {
  backChanged: boolean;
  open: boolean;
  progress: number;
  cardLoading: boolean;
  confirmDisabled: boolean;
  confirmLoading: boolean;
  onBack: () => void;
  onConfirm: () => void;
  onBlurbChange: (blurb: string) => void;
  confirmLabel?: string;
};

export default function ConfirmCard({
  open,
  onBack,
  onConfirm,
  backChanged,
  onBlurbChange,
  confirmDisabled,
  confirmLoading,
  cardLoading,
  progress,
  confirmLabel
}: Props) {
  const [showEditBlurb, setShowEditBlurb] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const { cardResult } = useGetPlayerCard();

  const handleBlurbChange = (blurb: string) => {
    setShowEditBlurb(false);
    if (cardResult?.athlete_info?.blurb === blurb) {
      return;
    }
    onBlurbChange(blurb);
  };

  const getImageUrl = (imageUrl: string, imageType: string) => {
    const searchParams = new URLSearchParams({
      imageUrl,
      imageType
    });
    return `/api/v1/get-image?${searchParams}`;
  };

  const toFile = async (url: string, filename: string, mimeType: string) => {
    const imageUrl = getImageUrl(url, mimeType);
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: mimeType });
    return file;
  };

  const share = async () => {
    try {
      if (!cardResult?.card_preview) {
        showToast("Ran into an issue preparing your images. Please try again.");
        return;
      }
      setShareLoading(true);
      const frontCardFile = await toFile(
        cardResult.card_preview.card_front_preview_uri,
        "card-front.png",
        "image/png"
      );
      const backCardFile = await toFile(
        cardResult.card_preview.card_back_preview_uri,
        "card-back.png",
        "image/png"
      );
      const shareData: ShareData = {
        files: [frontCardFile, backCardFile],
        title: "My StatLegend Card!",
        text: `Check out this cool sports card I made on StatLegend.com! "${cardResult?.athlete_info?.blurb}"`
      };
      if (!navigator.canShare(shareData)) {
        showToast("Woops, your browser does not support sharing our image format.");
        setShareLoading(false);
        return;
      }
      sendGTMEvent({
        event: "card_shared",
        value: "card_shared"
      });
      await navigator.share(shareData);
    } catch (err) {
      if (err?.name !== "AbortError") {
        showToast("Ran into an unexpected issue. Please try again.");
      }
    } finally {
      setShareLoading(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent className="bg-legend-green text-black" hideClose={true}>
        <DialogHeader>
          <DialogTitle className="text-center text-xl text-black sm:text-3xl">
            Confirm card design & content
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-grow flex-col items-center gap-y-6 pt-1 md:gap-y-8">
          <DialogDescription className="pt-2 text-center text-sm sm:text-base">
            Please review the design and content of the card. Once confirmed, no changes can be made
            to the text or layout of the card.
          </DialogDescription>
          {cardLoading ? (
            <LoadingCard progress={progress} />
          ) : (
            <PlayerFlippableCard
              defaultFlipped={backChanged}
              loadingMessage={backChanged ? "Generating a customized card back" : undefined}
              extraControl={
                <div className="flex gap-x-5">
                  <IconButton
                    icon={<PencilIcon size="20" />}
                    label="Edit blurb"
                    onClick={() => setShowEditBlurb(true)}
                  />
                  {!!navigator.canShare && (
                    <IconButton
                      icon={<Share2Icon size="20" />}
                      onClick={share}
                      label="Share"
                      loading={shareLoading}
                    />
                  )}
                </div>
              }
            />
          )}

          <div className="flex w-full flex-grow flex-col justify-end">
            <div className="grid grid-cols-2 gap-x-3 md:gap-x-4">
              <Button variant="outline" onClick={onBack}>
                Back
              </Button>
              <Button disabled={confirmDisabled} loading={confirmLoading} onClick={onConfirm}>
                {confirmLabel ?? "Confirm"}
              </Button>
            </div>
          </div>
        </div>
        <EditBlurbModal
          open={showEditBlurb}
          defaultBlurb={cardResult?.athlete_info?.blurb}
          onChange={handleBlurbChange}
          onCancel={() => setShowEditBlurb(false)}
        />
      </DialogContent>
    </Dialog>
  );
}
